.dashboard_hero{
    display: block;
}
.dashboard_hero h1{
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 15px;
}
.dashboard_menu{
    display: block;
}
.dashboard_menu li{
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 10px;
}
.dashboard_menu li:first-child{
    padding-left: 0px;
}
.dashboard_menu li a{
    display: block;
    padding: 10px 0px;
    color: #7c8186;
    text-decoration: none;
    border-bottom: 2px solid transparent;
}
.dashboard_menu li:hover a,.dashboard_menu li.active a{
    text-decoration: none;
    border-bottom: 2px solid #1aa0d7;
    color: #000;
}
.over_view{
    display: block;
    margin-top: 30px;
}
.over_view h4{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}
.over_view p{
    font-size: 14px;
}
.dah_card{
    padding: 15px;
    margin-top: 30px;
    color: #676d73;
    
}
.dah_card span{
    color: #3db989;
    font-weight: bold;
}
.dah_card.active{
    padding: 15px;
    margin-top: 30px;
    color: #000;
    border-bottom: 2px solid #1aa0d7;
    background: #fafafa;
    
}
.dah_card h4{
    font-weight: 600;
    margin-bottom: 5px;
}
.dah_card big{
    font-size: 40px;
    font-weight: 600;
}
.dah_card p{
    
    font-size: 14px;
}
.activity{
    padding-top: 30px;
    text-align: center;
    font-size: 14px;
}
.activity h2{
    padding: 15px 0px;
    font-weight: 600;
    font-size: 16px;
}
.activity span{
    font-weight: 600;
    color: #1aa0d7;
}
.table_area{
    display: block;
    padding-top: 30px;
}