.nav a {
  color: inherit;
}
.navbar-brand {
    width: 122px;
    height: 26px;
}

.nav a:hover {
  text-decoration: none;
  color: inherit;
}

.logout {
  cursor: pointer;
}
