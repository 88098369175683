.profile input[type="number"]::-webkit-inner-spin-button,
.profile input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.steptrigger {
  background: #fff;
  border-top: #e4e5e6 1px solid;
  padding: 15px 0px;
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.short-des {
  padding-bottom: 20px;
}

.short-des--1 {
  padding-bottom: 10px;
}
.short-des h3 {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
}
.short-des p {
  font-size: 14px;
  line-height: 20px;
}

.profile__required-field {
  color: red;
}

.birthday__fields label {
  font-size: 14px;
}

.payment__label {
  width: 100%;
}

.StripeElement {
  padding: 11px 15px !important;
}

.StripeElement--focus {
  outline: none;
  color: #2f3033;
  border-color: #009fd9 !important;
}
