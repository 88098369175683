.modal-danger {
  color: #fff !important;
  background-color: #d62d31 !important;
  border-color: #d62d31 !important;
  text-align: center !important;
  font-size: 14px !important;
  position: absolute !important;
  width: 100%;
  top: -60px;
}
