.photo-title{
    font-weight: 600;
    font-size: 20px;
}
.photobox{
    padding: 50px 0px;
}
.photo-content{
    font-size: 12px;
    padding: 15px 0px;
}
.avatar_container{
    background: #fff;
    border: 2px dashed #e9eced;
    text-align: center;
    direction: block;
    padding: 30px;
}
.image_holder{
    width: 100px;
    height: 100px;
    display: inline-block;
    border-radius: 50%;
    background: #fafafa;
}
.image_holder img{
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.phototips{
    background: #fff;
    border-left: 3px solid #5a6cdf;
    padding: 10px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.19);
-moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.19);
box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.19);
}
.phototips h4{
    font-weight: 600;
    font-size: 16px;
}
.phototips ul{
    display: block;
    padding: 15px;
   
}
.phototips ul li{
    
    padding: 5px 0px;
    list-style:circle; 
    font-size: 12px;
}
.upload_sytem{
    padding-top:10px; 
}
.upload_btn{
    display: inline-block;
    text-align: center;
    position: relative;
}