.project_hero{
    display: block;
    text-align: center;
    padding: 30px 0px;
}
.project_hero h1{
   font-size: 20px;
   font-weight: 600;
}
.project_hero p{
    font-size: 15px;
    line-height: 22px;
}
.project_hero .highlight{
    display: inline-block;
    position: relative;
    padding:20px 0px;
    color: #1aa0d7;
    font-size: 18px;
    font-weight: 600;
}
.project_hero .highlight::before{
    content: "";
    position: absolute;
    left: 0;
    bottom:0;
    width: 50%;
    left: 25%;
    background: #d3d4d5;
    height: 1px;
}
.title{
    font-weight: 600;
    font-size: 20px;
}
.project_wrapper{
    background: #fff;
    border-radius: 5px;
    border:1px solid #eaeaea;
    margin: 15px 0px;
    border-bottom: 2px solid #eaeaea;
}
.project_wrapper .project_header,.project_wrapper .project_container{
    padding: 20px 15px;
    
}
.project_wrapper .project_header{
    border-bottom:1px solid #eaeaea;
}
.project_header .project_title{
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
}
.project_header .project_title:before{
    content: ".";
    font-weight: 600;
    font-size:60px;
    position: absolute; 
    left: 0;
    top: -43px;
    color: #1aa0d7;
}
.project_header .project_time{
    font-size: 13px;
    color: #9fa2a6;
}
.pass_btn{
    color: #6c757d;
}

.project_ses{
    display: block;
}
.project_ses li{
    display: flex;
    padding: 5px 0px;
}
.project_ses li .icosn{
    padding-right: 10px;
    font-size: 25px;
    flex: 0;
    vertical-align: top;
}
.project_ses li .text_content{
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    color: #404144;
}
.labels{
    padding: 10px 15px;
    border: 1px solid #e9eced;
    border-radius: 3px;
    margin:5px; 
}