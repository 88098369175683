@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: "Montserrat", sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  background: #fafafa;
}
ol,
ul {
  list-style: none;
}
img {
  max-width: 100%;
}
.bg-default {
  background: #fafafa;
}
.full-height {
  min-height: 100vh;
}
.mybg {
  background: #fff;
  border-bottom: 1px solid #eaedee;
  padding: 0 15px;
}
.mybg .nav-link.active {
  border-bottom: 2px solid #e4e5e6;
}
.mybg .nav-link {
  border-bottom: 2px solid transparent;
  margin: 0px 10px;
  padding: 20px 10px;
  font-size: 13px;
}
.mybg .nav-item.active .nav-link {
  border-bottom: 2px solid #FF0083;
}
.mybg .nav-item:hover .nav-link {
  border-bottom: 2px solid #FF0083;
}
.fancyheading {
  color: #2e3032;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
}
.heading {
  color: #2e3032;
  font-size: 30px;
  font-weight: 600;
}
.heading.medium {
  font-size: 25px;
}

.heading.small {
  font-size: 20px;
}
.heading span {
  color: #FF0083;
}

.section-default {
  padding: 20px 0px;
}
.tp-form-fields {
  padding: 0;
}
.box-holder {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e9ebec;
  padding: 30px;
  margin: 10px 0px;
}
.text-divider {
  text-align: center;
  position: relative;
  margin: 15px 0px;
}
.text-divider:after {
  position: absolute;
  right: 0;
  width: 40%;
  content: "";
  top: 50%;
  height: 1px;
  background: #e4e5e6;
}
.text-divider:before {
  position: absolute;
  left: 0;
  width: 40%;
  content: "";
  top: 50%;
  height: 1px;
  background: #e4e5e6;
}
.small-text {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
}
.link-default {
  color: #FF0083;
}
.footer {
  background: #f9f9f9;
  border-top: 1px solid #f1f3f3;
  padding: 15px 0px;
  font-size: 14px;
  color: #696f74;

  width: 100%;
}
.footer-menu {
  text-align: right;
}
.footer-menu li {
  display: inline-block;
  padding: 0px 15px;
}
.footer-menu li a {
  font-size: 14px;
  color: #696f74;
}

.info-block {
  display: block;
  text-align: center;
  padding: 15px 0px;
}
.info-block h4 {
  font-weight: 600;
  font-size: 14px;
  color: #000;
}
.info-block .icons-s {
  font-size: 30px;
  color: #FF0083;
}
.my_modal_content {
  display: block;
  padding-bottom: 30px;
}
.my_modal_content h4 {
  font-weight: 600;
  font-size: 20px;
}
.my_modal_content p {
  font-size: 13px;
  line-height: 22px;
}
.my_modal_content label {
  font-size: 14px;
  font-weight: 600;
}

.modal-header {
  border: 0px;
}
.modal-content {
  border-radius: 0px;
}
.modal-body {
  padding: 0px 30px;
}
button:hover,
button:focus {
  outline: none;
}
.tp-label {
  font-size: 13px;
}
.powered{
  text-align: left;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 600;
}
.powered i{
  color: #ed4141;
}
.footer-copy{
  text-align: left;
}
.tp-button-shadow{
    background-color: #FF0083;
    /*FF0083*/
    color: #fff;
    border: 0;
    box-shadow: 0px 10px 40px -10px rgba(255,0,131,0.5);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -moz-transition: all 0.2s, -moz-transform 0.5s;
    -o-transition: all 0.2s, -o-transform 0.5s;
    -webkit-transition: all 0.2s, -webkit-transform 0.5s;
    transition: all 0.2s, transform 0.5s;
  }
.tp-button{
    background-color: #FF0083;
    /*FF0083*/
    color: #fff;
    border: 0;
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -moz-transition: all 0.2s, -moz-transform 0.5s;
    -o-transition: all 0.2s, -o-transform 0.5s;
    -webkit-transition: all 0.2s, -webkit-transform 0.5s;
    transition: all 0.2s, transform 0.5s;
  }
.tp-button:active,
.tp-button:hover {
  color: #fff;
}
.tp-button:focus {
  outline: none;
  text-decoration: underline;
  color: #fff;
  background-color: #FF0083;
  border-color: #FF0083;
}
.tp-button--secondary {
  background-color: #fff;
  border-color: #d3d4d5;
  color: #FF0083;
}
.tp-button--secondary:active,
.tp-button--secondary:hover {
  color: #FF0083;
}
.tp-button--secondary:focus {
  color: #FF0083;
  background-color: #fff;
  border-color: #d3d4d5;
}
.tp-button--tertiary {
  background-color: #fff;
  border-color: #d3d4d5;
  color: #676d73;
}
.tp-button--tertiary:active,
.tp-button--tertiary:hover {
  color: #676d73;
}
.tp-button--tertiary:focus {
  color: #676d73;
  background-color: #fff;
  border-color: #676d73;
}
.tp-button--caution {
  background-color: #fff;
  border-color: #d3d4d5;
  color: #ff5a5f;
}
.tp-button--caution:active,
.tp-button--caution:hover {
  color: #ff5a5f;
}
.tp-button--caution:focus {
  color: #cc3553;
  background-color: #fff;
  border-color: #d3d4d5;
}
.tp-button--solid {
  background-color: #fff;
  border-color: #fff;
  color: #2f3033;
}
.tp-button--solid:active,
.tp-button--solid:hover {
  color: #2f3033;
}
.tp-button--solid:focus {
  color: #2f3033;
  background-color: #fff;
  border-color: #fff;
}
.tp-button--line {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.tp-button--line:active,
.tp-button--line:focus,
.tp-button--line:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
  color: #fff;
}
.tp-button--clear,
.tp-button--clear:active,
.tp-button--clear:focus,
.tp-button--clear:hover {
  color: #FF0083;
  background: none;
  border-color: transparent;
}
.tp-button--full,
.tp-button--full-below-small {
  width: 100%;
  text-align: center;
}
@media (min-width: 482px) {
  .tp-button--full-below-small {
    width: auto;
  }
}
.tp-button--small {
  font-size: 14px;
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 40px;
}
.tp-button--disabled,
.tp-button--disabled:active,
.tp-button--disabled:focus,
.tp-button--disabled:hover,
.tp-button--disabled:visited,
.tp-button[disabled],
.tp-button[disabled]:active,
.tp-button[disabled]:focus,
.tp-button[disabled]:hover,
.tp-button[disabled]:visited {
  cursor: default;
  color: #fff;
  background-color: #FF0083;
  border-color: #FF0083;
}
.tp-button--disabled.tp-button--secondary,
.tp-button--disabled:active.tp-button--secondary,
.tp-button--disabled:focus.tp-button--secondary,
.tp-button--disabled:hover.tp-button--secondary,
.tp-button--disabled:visited.tp-button--secondary,
.tp-button[disabled].tp-button--secondary,
.tp-button[disabled]:active.tp-button--secondary,
.tp-button[disabled]:focus.tp-button--secondary,
.tp-button[disabled]:hover.tp-button--secondary,
.tp-button[disabled]:visited.tp-button--secondary {
  color: #FF0083;
  background-color: #fff;
  border-color: #d3d4d5;
}
.tp-button--disabled.tp-button--tertiary,
.tp-button--disabled:active.tp-button--tertiary,
.tp-button--disabled:focus.tp-button--tertiary,
.tp-button--disabled:hover.tp-button--tertiary,
.tp-button--disabled:visited.tp-button--tertiary,
.tp-button[disabled].tp-button--tertiary,
.tp-button[disabled]:active.tp-button--tertiary,
.tp-button[disabled]:focus.tp-button--tertiary,
.tp-button[disabled]:hover.tp-button--tertiary,
.tp-button[disabled]:visited.tp-button--tertiary {
  color: #d3d4d5;
  background-color: #fff;
  border-color: #d3d4d5;
}
.tp-button--disabled.tp-button--caution,
.tp-button--disabled:active.tp-button--caution,
.tp-button--disabled:focus.tp-button--caution,
.tp-button--disabled:hover.tp-button--caution,
.tp-button--disabled:visited.tp-button--caution,
.tp-button[disabled].tp-button--caution,
.tp-button[disabled]:active.tp-button--caution,
.tp-button[disabled]:focus.tp-button--caution,
.tp-button[disabled]:hover.tp-button--caution,
.tp-button[disabled]:visited.tp-button--caution {
  color: #ffb0b0;
  background-color: #fff;
  border-color: #d3d4d5;
}
.tp-button--disabled.tp-button--solid,
.tp-button--disabled:active.tp-button--solid,
.tp-button--disabled:focus.tp-button--solid,
.tp-button--disabled:hover.tp-button--solid,
.tp-button--disabled:visited.tp-button--solid,
.tp-button[disabled].tp-button--solid,
.tp-button[disabled]:active.tp-button--solid,
.tp-button[disabled]:focus.tp-button--solid,
.tp-button[disabled]:hover.tp-button--solid,
.tp-button[disabled]:visited.tp-button--solid {
  color: #676d73;
  background-color: hsla(0, 0%, 100%, 0.5);
  border-color: transparent;
}
.tp-button--disabled.tp-button--clear,
.tp-button--disabled:active.tp-button--clear,
.tp-button--disabled:focus.tp-button--clear,
.tp-button--disabled:hover.tp-button--clear,
.tp-button--disabled:visited.tp-button--clear,
.tp-button[disabled].tp-button--clear,
.tp-button[disabled]:active.tp-button--clear,
.tp-button[disabled]:focus.tp-button--clear,
.tp-button[disabled]:hover.tp-button--clear,
.tp-button[disabled]:visited.tp-button--clear {
  background-color: transparent;
  border-color: transparent;
  color: #d3d4d5;
}
.tp-button--disabled.tp-button--line,
.tp-button--disabled.tp-button--reverse,
.tp-button--disabled:active.tp-button--line,
.tp-button--disabled:active.tp-button--reverse,
.tp-button--disabled:focus.tp-button--line,
.tp-button--disabled:focus.tp-button--reverse,
.tp-button--disabled:hover.tp-button--line,
.tp-button--disabled:hover.tp-button--reverse,
.tp-button--disabled:visited.tp-button--line,
.tp-button--disabled:visited.tp-button--reverse,
.tp-button[disabled].tp-button--line,
.tp-button[disabled].tp-button--reverse,
.tp-button[disabled]:active.tp-button--line,
.tp-button[disabled]:active.tp-button--reverse,
.tp-button[disabled]:focus.tp-button--line,
.tp-button[disabled]:focus.tp-button--reverse,
.tp-button[disabled]:hover.tp-button--line,
.tp-button[disabled]:hover.tp-button--reverse,
.tp-button[disabled]:visited.tp-button--line,
.tp-button[disabled]:visited.tp-button--reverse {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  opacity: 0.35;
}
.tp-button-reset {
  padding: 0;
  background: none;
  border: none;
}
.tp-button-reset:active {
  outline: none;
}
.tp-button--reset {
  padding: 0;
  background: none;
  border: none;
}
.tp-button--reset:active {
  outline: none;
}
.tp-button-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tp-button-icon__icon {
  flex: none;
  margin: 0 12px 0 0;
  fill: currentColor;
}








.newcard {
  border-left: #5f70e0 solid 3px;
}
.newcard h4 {
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 15px;
}
.newcard p {
  font-size: 12px;
}
.ss-checkbox-label span {
  font-size: 12px;
  font-weight: 600;
}
.ss-checkbox-label p {
  font-weight: 600;
  font-size: 12px;
}
.arears {
  display: block;
}
.arears .area_nav {
  display: flex;
}
.arears .area_nav li {
  display: inline-flex;
  width: 100%;
}
.arears .area_nav li a {
  display: block;
  padding: 15px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.arears .area_nav li.active a {
  background: #1aa0d6;
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
}
.countyName {
  padding-top: 10px;
}
.countyName h4 {
  font-weight: 600;
  font-size: 14px;
}
.countyName p {
  font-size: 12px;
  color: #696f74;
}
.divider_box {
  position: relative;
  border-bottom: 1px solid #f1f3f3;
}
.amountvalue {
  display: block;
}
.amountvalue h4 {
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 10px;
}
.amountvalue h4 span {
  color: #1aa0d6;
}
.amountvalue p {
  font-size: 14px;
  color: #696f74;
}
.range_area p {
  font-size: 14px;
  padding-top: 15px;
  color: #696f74;
}
.labelare {
  background: #f9f9f9;
  margin-top: 15px;
  font-weight: 600;
  font-size: 14px;
  border: 0px;
}
.labelare .badge-pill {
  padding: 7px 15px;
}
.icon-grey {
  color: #e4e5e6;
  font-size: 50px;
}
.accordion .card {
  border: 0px;
  padding: 0px;
  border-top: 1px solid #e9ebec;
}
.accordion .card:first-child {
  border: 0;
}
.accordion .card-body {
  padding: 10px 0px;
  font-size: 12px;
}

.faq-collap {
  background: #fff;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 0;
}
.faq-collap:first-child {
  border: 0;
}

.faq-collap:after {
  font: normal normal normal 16px/1 LineAwesome;
  content: "\f110";
  float: right;
  color: grey;
}
.big-icon-green {
  color: #43c1a4;
  font-size: 100px;
}

.mt3 {
  margin-top: 16px !important;
}
.steps_modal_info {
  display: flex;
  margin: 30px 0px;
}
.steps_modal_info .sei_icon {
  padding-right: 10px;
  font-size: 30px;
  vertical-align: top;
}
.steps_modal_info .m_content {
  flex: 1;
}
.show-mobile {
  display: none;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #FF0083;
}

@media (max-width: 980px) {
  .mybg {
    padding: 15px 30px;
  }
  .mybg .nav-link {
    border-bottom: 2px solid transparent;
    margin: 0px;
    padding: 10px 0px;
    font-size: 15px;
    font-weight: 600;
  }
  .mybg .nav-link.active {
    border-bottom: 2px solid transparent;
  }
  .footer,
  .footer-menu {
    text-align: center;
  }
  .footer-menu {
    padding: 10px 0px;
  }
}
@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
  .show-mobile {
    display: block;
  }
}
