.map_box{
    height: 300px;
  
    position: relative;
}
.map_box .cirular-box{
    width: 100px;
    height: 100px;
    position: absolute;
    bottom:-50px;
    background: #676d73;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 90px;
    font-weight: 600;
    font-size: 25px;
    border: 5px solid #fff;
}
.prf_name{
    display: block;
    text-align: center;
    padding: 70px 0px 30px 0px;
    background: #fff;
}
.prf_name h1{
    font-size: 18px;
    font-weight: 600;
}
.prf_name p{
    font-size: 15px;
}
.pf_info_bx{
    background: #fff;
}
.pf_info_bx .d_info{
    text-align: center;
    padding: 15px;
}
.pf_info_bx .d_info.border-right{ border-right: 1px solid rgb(231, 231, 231);}
.d_info h4{
    font-weight: 600;
}
.jb_in{
    display: block;
}
.jb_in li{
    display: block;
    border-bottom: 1px solid #e9eced;
    padding:10px 0px;
}
.jb_in li h5{
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 10px;
}
.jb_in li p{
    font-size: 14px;
}
.av_profile{
    display: flex;
}
.av_profile .image_hold{
    width: 50px;
    height:50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right:15px; 
}
.profile_nme{
    font-size: 14px;
}
.profile_nme h4{
    font-weight: 600;
}
.intro{
    display: block;
    padding-top:15px;
    font-size: 14px; 
}
.right_side_bar{
    padding-top: 30px;
}
.right_side_bar .title{
    font-size: 16px;
    margin-bottom: 15px;
}
.setion{
    display: block;
    font-size: 14px;
}
