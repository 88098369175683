.edit_profile_warpper {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e9eced;
  margin-bottom: 15px;
}
.edit_profile_section {
  border-bottom: 1px solid #e9eced;
  padding: 15px;
  font-size: 14px;
}
.edit_profile_warpper h1 {
  font-weight: 600;
  font-size: 16px;
}
.slider {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.slider .icon {
  margin-right: 10px;
  flex: 0%;
  font-size: 30px;
}
.staging {
  display: block;
  position: relative;
}
.sldier_content {
  padding-right: 10px;
}
.staging ul {
  display: block;
  margin-top: 15px;
}
.staging ul li {
  display: inline-block;
  position: relative;
  width: 33.33%;
}
.staging ul li.active .icons {
  color: #1280ab;
}
.staging ul li.active:before {
  border-bottom: 5px dashed #1280ab;
}

.staging ul li:before {
  width: 100%;
  content: "";
  border-bottom: 5px dashed #ccc;
  position: absolute;
  left: 0;
  top: 50%;
}
.icons {
  width: 40px;
  height: 40px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  color: #000;
  position: relative;
  line-height: 40px;
  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.19);
  box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.19);
}
.silder_container {
  padding: 0px 30px;
  position: relative;
}
.silder_container .next,
.silder_container .next:hover {
  right: 0px;
  position: absolute;
  font-size: 20px;
  color: #000;
  text-decoration: none;
}
.silder_container .prev,
.silder_container .prev:hover {
  left: 0px;
  position: absolute;
  font-size: 20px;
  color: #000;
  text-decoration: none;
}
.slider_trigger {
  position: absolute;
  top: 10%;
  left: 0px;
  width: 100%;
}
.profile_holder {
  display: flex;
  align-items: center;
}
.profile_holder .image_holder {
  width: 100px;
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
}
.profile_holder .image_holder img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.profile_holder .profie_info {
  padding-left: 15px;
}
.profile_holder .profie_info h4 {
  font-weight: 600;
}
.detais_info {
  padding-top: 30px;
}
.detais_info ul {
  display: block;
}
.detais_info ul li {
  display: block;
  padding: 10px 0px;
  border-bottom: 1px solid #e9eced;
}
.detais_info ul li:last-child {
  border: 0;
}
.de_info {
  display: flex;

  align-items: center;
}
.de_info .leftinfo {
  flex: 0%;
}
.image_placeholder {
  background: #fafafa url("../../assets/images/imicon.jpg") no-repeat center;
  border-radius: 3px;
  border: 1px dashed #e2e2e3;
  height: 120px;
  overflow: hidden;
  width: 100%;
  display: block;
  margin: 10px 0px;
}

.image_uploadplaceholder {
  background: #fafafa;
  border-radius: 3px;
  border: 1px dashed #e2e2e3;
  height: 120px;
  overflow: hidden;
  width: 100%;
  display: block;
  margin: 10px 0px;
}
.upload_button {
  height: 100%;
  text-align: center;
  width: 100%;
  vertical-align: center;
  padding: 30px 0px;
}
.new_card {
  padding: 15px;
  flex-direction: initial;
}
.new-content {
  flex: 0%;
  padding-right: 10px;
}
.lil_section {
  padding: 5px 0px;
  text-align: center;
}
.lil_section span {
  color: #4db5df;
  margin-right: 10px;
}
.tp-button i {
  font-size: 16px;
  padding-right: 5px;
}
.p_heading {
  display: flex;
}
.p_heading .title {
  font-size: 14px;
  flex: 0%;
}
.btns {
  text-align: right;
}
.save_btn,
.save_btn:hover {
  font-weight: 600;
  text-decoration: none;
  color: #1280ab;
  margin-right: 5px;
}
.can_btn,
.can_btn:hover {
  font-weight: 600;
  text-decoration: none;
  color: #fc6168;
}
.payment_type {
  padding: 10px 0px;
}
.payment_type li {
  border-bottom: 1px solid #e2e2e3;
  padding: 10px 0px;
}
.payment_type li:last-child {
  border-bottom: 0;
}
.reviews_container {
  display: flex;
}
.reviews_overall {
  padding-right: 20px;
}
.reviews_overall .r_cate {
  flex: 1%;
}

.detail_review {
  display: flex;
}
.detail_review .number {
  flex: 0%;
  padding-right: 5px;
}
.detail_review .number i {
  color: #e9ecef;
}
.detail_review .progress_bar {
  width: 100px;
}
.detail_review .persentage {
  padding-left: 5px;
}
.times {
  margin-top: 15px;
  display: block;
}
.times li {
  padding: 5px 0px;
}
.note {
  padding: 15px 0px;
  border-top: 1px solid #e2e2e3;
  border-bottom: 1px solid #e2e2e3;
  margin: 15px 0px;
}
.note i {
  font-size: 20px;
  padding-right: 5px;
}
.note span {
  font-weight: 600;
  color: #4db5df;
}
.profile_socil .tp-button {
  margin: 5px;
  padding: 10px;
  text-decoration: none;
}
