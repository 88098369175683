.reviews_heading{
    display: block;
}
.reviews_heading h1{
    font-size: 20px;
    font-weight: 600;
}
.reviews_heading p{
    padding: 15px 0px;
    font-size: 14px;
}
.reviews_form{
    display: block;
}
.reviews_form h4{
    font-weight: 600;
    font-size: 16px;
}
.addcus{
    margin: 10px 0px;
}
.addcus,.addcus:hover{
    font-size: 16px;
    font-weight: 600;
    color: #1aa0d7;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
}
.addcus i{
    font-size: 25px;
    padding-right:10px;

}
.border-left-sse{
    border-left: 5px solid #5a6cdf;
    padding: 15px;
}
.border-left-sse h4{
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 10px;
}
.border-left-sse p{
    font-size: 12px;
}
.review_card_holder{
    display: block;
    padding-top: 15px;
}
.review_card_holder p{
    font-size: 14px;
}
.reviewcard{
    padding: 15px;
    text-align: center;
}
.reviewcard .imge_thumb{
    width: 100px;
    height: 100px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
}
.star_ratings{
    color: #fcbd32;
}