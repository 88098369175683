@import url('https://fonts.googleapis.com/css?family=Merriweather:400,700&display=swap');
.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #FFF !important;

}
.hero{
    background: transparent;
    position: relative;
    text-align: center;
    margin: 0;
    overflow: hidden;
    
}
.clouds-hero{
    background: url(../assets/images/clouds.png) 0px 0px / contain no-repeat;
    animation: animateCloud 25s linear infinite;
    position: absolute;
    height: 100%;
    width: 100%;

}
.hero:before{
    content: "";
    position: absolute;
    width: 705px;
    height: 281px;
    background-image: url(../assets/images/building.svg);
    bottom: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-position: left bottom;

}
.hero:after{
        content: "";
        position: absolute;
        width: 705px;
        height: 281px;
        background-image: url(../assets/images/building.svg);
        bottom: 0px;
        right: 0px;
        background-repeat: no-repeat;
        background-position: right bottom;
        
   
}


@keyframes animateCloud {
    0% {
        margin-left:40%;
    }
    100% {
        margin-left:0%;
    }
}

.hero h1{ font-size: 50px; color: #4a4a4a; padding-bottom:15px;  font-family: 'Merriweather', serif; line-height:60px;}
.hero h2{ font-size: 20px; color: #4a4a4a; padding-bottom:25px;}
.button-area{ padding-top:50px; z-index: 99; position: relative;} 
.purple_btn{
    background-color: rgb(255, 0, 131);
    box-shadow: rgba(255, 0, 131, 0.5) 0px 10px 40px -10px;
    border-radius: 5px;
    padding: 18px 44px;
    color: #fff;
    text-transform: uppercase;
    font-size:16px;
    font-weight: 600;

}
.purple_btn:hover{
    background-color: rgb(220, 0, 115);
    transform: translateY(1px);
    color: #fff;
    text-decoration: none;

}

.purple_btn_full{
    background-color: rgb(255, 0, 131);
    box-shadow: rgba(255, 0, 131, 0.5) 0px 10px 40px -10px;
    border-radius: 5px;
    padding:15px ;
    color: #fff;
    text-transform: uppercase;
    font-size:16px;
    font-weight: 600;
    display: block;
}
.purple_btn_full:hover{
    background-color: rgb(220, 0, 115);
    color: #fff;
    text-decoration: none;
}
.liner-btn{ display: inline-block; margin-top: 20px; font-size: 20px; color: rgb(255, 0, 131); background: transparent; border: 0;
outline: none;}
.liner-btn:hover,.liner-btn:focus{ text-decoration: none; color: #4a4a4a; outline: none;}
.liner-btn i{ margin-right: 10px; color: #4a4a4a;}

.section-media{
    background: url(../assets/images/bg-press.png) center center / contain no-repeat rgb(247, 247, 247);
    width: 100%;
    height:66px;
    
}

.section-information{ 


    background: url(../assets/images/bg-lead-generation.png) center center / 1281px 680px no-repeat, url(../assets/images/repeat.png) center bottom -2px / 1366px 680px repeat-x;
    border-bottom: 2px solid rgb(236, 236, 236);
    height: 680px;
    padding:80px 0px;
    text-align: center;
}
.section-information h2{ font-size:40px; font-weight:700; color: #4a4a4a; line-height: 50px;}
.section-information p{ font-size: 20px; color: #575757; line-height: 25px; padding-top: 15px;}

.instant{ padding-top:80px; text-align: center; border-bottom:1px solid  #4a4a4a;}
.instant h2{ font-size:40px; font-weight:700; color: #4a4a4a; line-height: 50px;}
.instant p{ font-size: 20px; color: #575757; line-height: 25px; padding-top: 15px;}
.mobile-box{
    width: 100%;
    height: 360px;
    background-size: contain;
    background: url(../assets/images/bg-app.png) center bottom -2px / 343px 329px no-repeat transparent;
}
.arrow-left,.arrow-right{ position: relative; text-align: center; padding-top:125px;
font-size:30px; color: #4a4a4a;}
.arrow-left::before {
    content: "";
    position: absolute;
    right: 40px;
    bottom: -45px;
    width: 28px;
    height: 32px;
    background: url(../assets/images/arrow.svg) no-repeat transparent;
}

.arrow-right::before {
    content: "";
    position: absolute;
    left: 40px;
    bottom: -45px;
    width: 28px;
    height: 32px;
    background: url(../assets/images/arrow-right.svg) no-repeat transparent;
}

.testimonial-section{
    background: #f7f7f7;
    padding: 80px 0px;
}
.stars{
    text-align: center;
    font-size: 50px;
    color: #ff0083;
}
.stars i{
    padding: 0px 2px;
}
.user_section{
    display: flex;
    align-items: center;
}
.user_section .useavatar{
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    
}
.user_section .useavatar img{
    width: 100%;
}
.user_section .usedes{ padding-left: 15px;}
.user_section .usedes p{ font-size: 16px; padding: 2px 0; margin: 0; line-height: 15px; font-weight: 600;}
.user_section .usedes p span{ color: #b7b7b7; font-size: 14px;}
.card-content{
    padding-top:15px;
    color: #626262;
}
.card-content span{
    color: #ff0083;
}
.blog_sldeo{
    padding-top:30px;
    position: relative;
}
.slider-item{
    padding:0px 15px;
}
.slick-prev:before, .slick-next:before{
    font-family: "LineAwesome" !important;
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #000 !important;
}
.slick-next:before{
    font-family: "LineAwesome" !important;
  
    color: #000;
}
.slick-prev:before{
    font-family: "LineAwesome" !important;
  
    color: #000;
}
.slick-dots li button:before {
    font-size:15px !important;
    color:#ececec !important;
    opacity: 1 !important;
}
.slick-dots li.slick-active button:before{
    color:#ff0083 !important;
}
.slick-dots{
    bottom: -50px !important;
}
.quoteation{
    padding-top:80px;
    text-align: center;
}
.small_print{  font-family: 'Merriweather', serif;
color: #bfbfbf;
font-style: italic;
 font-size:20px !important;
}

.quoteation h4{  font-family: 'Merriweather', serif;
color: #4a4a4a;
font-style: italic;}
.brand_auth{ padding-top:15px;}
.grey-card{
    background: #f7f7f7;
    text-align: center;
    margin-top:15px;
}
.grey-card ins{
    color: #bfbfbf;
    text-decoration: none;
    text-transform: uppercase;
}
.grey-card  h4{
    color: #4a4a4a;
    padding: 5px 0px;
    font-size: 25px;
}
.grey-card .price{
    font-family: 'Merriweather', serif;
    font-size: 50px;
    color: #4a4a4a;
    padding: 5px 0px;
}
.box-group{
    display: flex;
    margin-top:15px;
    flex-direction: row;
    justify-content: center;
}
.box1::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 40px;
    width: 182px;
    height: 113px;
    background: url(../assets/images/image_slide.svg) -5px 0px no-repeat transparent;
    background-position: -5px 0px;
}
.box2::before{
    left: 40px;
    width: 164px;
    height: 113px;
    content: "";
    position: absolute;
    top: 0px;
    background: url(../assets/images/image_slide.svg) -226px 0px no-repeat transparent;
    background-position: -226px 0px;
}
.box3::before{
    left: 40px;
    width: 164px;
    height: 113px;
    content: "";
    position: absolute;
    top: 0px;
    background: url(../assets/images/image_slide.svg) -428px 0px no-repeat transparent;
    background-position: -428px 0px;
}
.box,.box1,.box2,.box3{
    position: relative;
    
}
.box{
    margin:15px;
    text-align: center;
    width: 262px;
}
.box1,.box2,.box3{
    height: 113px;
}
.show-mobile{
    display: none;
}
.my_modal_content iframe{
width: 100%;
height: 500px;
border: 0;
}
.my_modal_content iframe{
    border: 0;
}
.videom .modal-content{
    background: transparent;
    border: 0;
}
.videom .modal-header{
    border-bottom: 0;
}
@media(max-width:1024px){
    .box-group{
       flex-wrap: wrap;
    }
    .hero h1,.section-information h2,.instant h2{
        font-size:30px;
        line-height: 45px;
    }
    .hero{
        height:700px;
    }
    .hero:before{
        background: none;
    }
    .arrow-left,.arrow-right,.section-media{
        display: none;
    }
    .show-mobile{
        display: flex;
        justify-content: center;
    }
    .info2{
        margin-top:20px;
        margin-left: 50px;
    }
    
    .stars{
        font-size: 25px;
    }

    
}
@media(max-width:980px){
    .user_section .usedes p{ font-size: 14px; }
.user_section .usedes p span{font-size: 12px;}

}